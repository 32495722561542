import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import { CircularProgress, Backdrop, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {getSppOrderById, updateSppOrder, getGoogleLocations} from "../../utils/api";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

function OrderUpdate() {
  const [infoSB, setInfoSB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [issetOrderId, setIssetorderId] = useState(false);
  const [data, setData] = useState({});
  const [orderId, setOrderId] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [sppIdErrorText, setSppIdErrorText] = useState('');
  const [accountNumberErrorText, setAccountNumberErrorText] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [submitErrorText, setSubmitErrorText] = useState('');
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);
  const closeInfoSB = () => setInfoSB(false);
  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="GMB Gorilla"
      content="Intake form and Report config have been updated successfully!"
      // dateTime="11 mins ago"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const changeValue = (e) => {
    setSubmitErrorText('');
    setPasswordErrorText('');
    let temp = {...data};
    temp[`${e.target.name}`] = e.target.value;
    setData(temp);
  };
  const checkOrderId = async () => {
    setSubmitErrorText('');
    setPasswordErrorText('');
    setLoading(true);
    let res = await getSppOrderById({ orderId });
    if (res.status) {
        setIssetorderId(true);
        setLoading(false);
        setData(res?.data);
    } else {
      setLoading(false);
      console.log(res);
      setSppIdErrorText(res?.message);
    }
    // setIssetorderId(true);
  }

  const fetachLocations  = async () => {
    setLoading(true);
    setLocation(null)
    setLocations([])
    let res = await getGoogleLocations({ accountNumber });
    if (res.status) {
      setLocations(res?.data)
      setLoading(false);
    } else {
      setLoading(false);
      console.log(res);
      setAccountNumberErrorText(res?.message);
    }
    // setIssetorderId(true);
  }

  const changeOrderId = (e) => {
    setData(null);
    setSubmitErrorText('');
    setIssetorderId(false);
    setSppIdErrorText('');
    setOrderId(e.target.value);
  }
  const updateIntakeform = async () => {
      if (!data?.password || data?.password == '') {
        setPasswordErrorText('Password is required.')
        return;
      }
      setLoading(true);
      setSubmitErrorText('');
      let res = await updateSppOrder({...data, location: location});
      if (res.status) {
        setInfoSB(true);
      } else {
        setSubmitErrorText(res.message);
      }
      setLoading(false);
  }
  return (
    <DashboardLayout>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Grid container spacing={6} sx={{ maxWidth: '1240px' }} >
          <Grid item xs={12} lg={12} py={4} >
            <Card>
              <div id="pdf_form">
                <MDBox p={4} sx={{ textAlign: 'center', pt: 6 }}>
                  <MDTypography variant="h3">SPP Intake Form</MDTypography>
                </MDBox>
                <Grid container py={2} px={2}>
                  <Grid item lg={12} >
                    <MDBox>
                      <Grid sx={{display: 'flex', justifyContent: 'center'}}>
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <Grid sx={{display: 'flex'}}>
                            <Grid sx={{width: '100%'}}>
                              <MDInput
                                label="SPP Order ID"
                                name="orderId" value={orderId}
                                onChange={(e) => changeOrderId(e)}
                                fullWidth
                                required
                              />
                              <Typography sx={{ color: 'red', fontSize: '13px' }}>{sppIdErrorText}</Typography>
                            </Grid>
                            <MDButton sx={{ width: '120px', ml: 1, borderRadius: '2px', height: '44px', background: '#04547C' }} variant="gradient" color="info" onClick={(e) => checkOrderId(e)} disabled={!orderId} >
                              Check
                            </MDButton>
                          </Grid>
                        </MDBox>
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDInput label="Business name" name="name" value={data?.name ? data?.name : ''  } onChange={(e) => changeValue(e)} fullWidth disabled={!issetOrderId} />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDInput label="Address" name="address" value={data?.address ? data?.address : ''} onChange={(e) => changeValue(e)} fullWidth disabled={!issetOrderId} />
                        </MDBox>
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDInput label="City" name="city" value={data?.city ? data?.city : ''} onChange={(e) => changeValue(e)} fullWidth disabled={!issetOrderId} />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDInput label="State" name="state" value={data?.state ? data?.state : ''} onChange={(e) => changeValue(e)} fullWidth disabled={!issetOrderId} />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDInput label="Zip Code" name="zip" value={data?.zip ? data?.zip : ''} onChange={(e) => changeValue(e)} fullWidth disabled={!issetOrderId} />
                        </MDBox>
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '100%',  px: 1 }} >
                          <MDInput
                            label="Keywords"
                            name="keywords"
                            value={data?.keywords ? data?.keywords : ''}
                            onChange={(e) => changeValue(e)}
                            fullWidth disabled={!issetOrderId}
                            helperText="* Please separate the keywords by comma."
                          />
                        </MDBox>
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDInput label="Business Phone Number" name="phone" value={data?.phone ? data?.phone : ''} onChange={(e) => changeValue(e)} fullWidth disabled={!issetOrderId} />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDInput label="Website URL" name="website" value={data?.website ? data?.website : ''} onChange={(e) => changeValue(e)} fullWidth disabled={!issetOrderId} />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Grid>
                </Grid>
              </div>
              <Grid></Grid>
              <Grid sx={{display: 'flex', justifyContent: 'center'}}>
                <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                  <Grid sx={{display: 'flex'}}>
                    <Grid sx={{width: '100%'}}>
                      <MDInput
                        label="GBP Group Account Number"
                        name="gbp_group_account_number" value={accountNumber}
                        onChange={(e) => {
                          setAccountNumberErrorText('');
                          setAccountNumber(e.target.value);
                      }}
                        fullWidth
                      />
                      <Typography sx={{ color: 'red', fontSize: '13px' }}>{accountNumberErrorText}</Typography>
                    </Grid>
                    <MDButton sx={{ width: '120px', ml: 1, borderRadius: '2px', height: '44px', background: '#04547C' }} variant="gradient" color="info" onClick={(e) => fetachLocations(e)} disabled={!accountNumber} >
                      Check
                    </MDButton>
                  </Grid>
                  {
                    locations?.length > 0 && (
                      <Grid>
                        <Autocomplete
                            value={location}
                            onChange={(event, newValue) => {
                              setLocation(newValue);
                            }}
                            id="controllable-states-demo"
                            options={locations}
                            getOptionLabel={(option) => option.title}
                            sx={{ width: '100%', mt: 2 }}
                            renderInput={(params) => <MDInput {...params} label="Select GBP" />}
                        />
                      </Grid>
                    )
                  }
                </MDBox>
              </Grid>
              <Grid sx={{mt: 2, mb: 6, textAlign: 'center' }}>
                <Grid>
                  <MDInput sx={{width: '250px'}} label="Password" name="password" value={data?.password ? data?.password : ''} onChange={(e) => changeValue(e)} disabled={!issetOrderId} required/>
                  <Typography sx={{ color: 'red', fontSize: '13px' }}>{passwordErrorText}</Typography>
                </Grid>
                  {
                    submitErrorText && (
                      <Grid sx={{mt: 2, textAlign: 'center', px: 4}}>
                        <Typography sx={{ color: 'red', fontSize: '14px', background: '#ffadad', py: 1, borderRadius: '8px' }}>{submitErrorText}</Typography>
                      </Grid>
                    )
                  }
                <MDButton sx={{ width: '250px', borderRadius: '2px', mt: 2, background: '#04547C' }} variant="gradient" color="info" onClick={() => updateIntakeform()} disabled={!issetOrderId || !orderId } >
                  Submit
                </MDButton>
              </Grid>
              {renderInfoSB}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default OrderUpdate;
