import axios from './axios';

const getSppOrderById = async (data) => {
  try {
    let res = await axios.post('/api/getSppOrderById', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return error;
  }
}

const getGoogleLocations = async (data) => {
  try {
    let res = await axios.post('/api/getGoogleLocations', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return error;
  }
}

const updateSppOrder = async (data) => {
  try {
    let res = await axios.post('/api/updateSppOrder', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' }});
    return res.data;
  } catch (error) {
    return error;
  }
}


export {
  updateSppOrder,
  getSppOrderById,
  getGoogleLocations
}